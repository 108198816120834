import React from "react";

import Quiz from "../components/Quiz";

// <OpenQuiz quizId="5ae1b55d1891850004825baf" />
// <RadioMatrix quizId="5adf0dbfa4b4e20004750f97" />
// <MultipleChoice quizId="5aeb108ee82e4700047ad305" />
// <MultipleOpen quizId="5ae7827d3f428d0004ccd663" />
// <Essay quizId="5aeb2177e82e4700047ad328" />

/**
 *     <Quiz quizid="5af09e5b0c6220000459011b" />
    <Quiz quizid="5ae775a73f428d0004ccd64a" />
    <Quiz quizid="5ae795723f428d0004ccd66b" />
    <Quiz quizid="5aeb108ee82e4700047ad305" />
    <Quiz quizid="5ae31185ea8ebc00049aa62e" />
    <Quiz quizid="5aeb2177e82e4700047ad328" />
*/

/* local quizzes for testing
  <Quiz quizid="5afaa8660f362265e947f467" />
  <Quiz quizid="5af9db99a5cb2d5d87f5d39a" />
  <Quiz quizid="5af04341cdfcdd53b29f417f" />
  <Quiz quizid="5af428579fabe31ff78d23d8" />
  <Quiz quizid="5af428819fabe31ff78d23d9" />
  <Quiz quizid="5af428f19fabe31ff78d23db" />
  <Quiz quizid="5af428d79fabe31ff78d23da" />
 */
const WidgetTest = () => (
  <div>
    <Quiz quizid="5afaa8660f362265e947f467" />
    <Quiz quizid="5af9db99a5cb2d5d87f5d39a" />
    <Quiz quizid="5af04341cdfcdd53b29f417f" />
    <Quiz quizid="5af428579fabe31ff78d23d8" />
    <Quiz quizid="5af428819fabe31ff78d23d9" />
    <Quiz quizid="5af428f19fabe31ff78d23db" />
    <Quiz quizid="5af428d79fabe31ff78d23da" />
    <Quiz
      quizid="5b2699e3a47db434242817e5"
      peerreviewid="5b269a15a47db434242817e6"
    />
    <Quiz
      quizid="5aeb2177e82e4700047ad328"
      peerreviewid="5af3b94a8125a40004605102"
    />
  </div>
);

export default WidgetTest;
